import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"
import Footer from "../components/Footer"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import SpecialEvent from "../components/EventosLollaMute/SpecialEvent"
import { useContext } from "react"
import {GlobalStateContext} from "../context/GlobalContextProvider"
import StepForm from "../components/TheBirraBehind/StepForm"
export default function({ location }) {
    // console.log("Data:", JSON.stringify(data, null, 2))
    let {events,venues} = useContext(GlobalStateContext)
    if(events && events[0]?.node?.province === "Buenos Aires") events = events.concat(venues)
    //console.log('event-cond',events[0].node.province)
    //console.log('events-venues:',{events,venues})
    const venue = {
      name: "events",
      background: "pending",
      title: "Disfrutá de los eventos más exclusivos con BUD",
      desktopTitleOne: "Disfrutá de los eventos más exclusivos con BUD",
      desktopTitleTwo: "",
    }
  
    const [age, setAge] = useState(true)
  
    let params = new URLSearchParams(location.search)
    const eventId = params.get("eid")
    
   
    // TODO 26/12: Simon dijo que nos van a pasar la data que va en el push
    //   useEffect(() => {
    //     let td = Cookies.get("_td")
    //     window.dataLayer.push({
    //       brand: "Budweiser",
    //       Zone: "SAZ",
    //       country: "ARG",
    //       city: "CABA",
    //       campaign: `${
    //         window.location.search.includes("?cmp=")
    //           ? window.location.search.split("?cmp=")[1].split("&")[0]
    //           : ""
    //       }`,
    //       cms: "0",
    //       event: "pageView",
    //       language: "es",
    //       login: false,
    //       pageName: "La musica en tus manos",
    //       pageType: "Basic page",
    //       SiteType: "Brand",
    //       product: "NA",
    //       sku: "NA",
    //       userUid: "",
    //       url: "https://www.budweiser.com.ar/lamusicaentusmanos/mute",
    //       url_campaign: `${
    //         window.location.search.includes("utm_campaign=")
    //           ? window.location.search.split("utm_campaign=")[1]
    //           : ""
    //       }`,
    //       Step_number: step ? `step_${step}` : "",
    //     })
    //   }, [step])
  
    useEffect(() => {
      let cookie = Cookies.get("age")
      let local = sessionStorage.getItem("age")
      // console.log("Cookie",cookie)
      if (cookie || local) setAge(false)
    }, [])
  
    return !age ? (
      <>
        <div style={{ overflowX: "hidden" }}>
          <SEO title="The Birra behind the music" />
          <Container fluid className="m-0 p-0">
            <section id="EventosSection">
              <SpecialEvent
                venue={venue}
                data={events}
                location={location}
                //   eventId={eventId}
              />
            </section>
            <Footer />
          </Container>
        </div>
      </>
    ) : (
      <AgeGate path={location?.pathname + location?.search} />
    )
  }